import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AppSettings } from "../models/AppSettings";
import { getAppSettings, getUserInfo } from "../services";

const defaultState: AppSettings = {
  API_ROOT: "",
  loaded: false,
  isAuthenticated: false,
  user: undefined,
};

export const AppContext = createContext<AppSettings>(defaultState);
export const useApp = () => useContext(AppContext);

const AppProvider: React.FC = ({ children }) => {
  const [settings, setSettings] = useState<AppSettings>(defaultState);
  const [loadingUser, setLoadingUser] = useState(true);

  const getSettings = async () => {
    const appSettings = await getAppSettings();
    if (appSettings) {
      setSettings(appSettings);
    }
  };

  const getUser = useCallback(async () => {
    const user = await getUserInfo(settings);
    if (user) {
      setSettings({ ...settings, user, isAuthenticated: true });
    } else {
      setSettings({ ...settings, user: undefined, isAuthenticated: false });
    }
  }, [settings]);

  const login = () => {
    window.location.href = settings.API_ROOT + "/auth/login?idp_identifier=basispoort&returnUrl=" + window.location.href;
  };

  const logout = () => {
    window.location.href = settings.API_ROOT + "/auth/logout?idp_identifier=basispoort&returnUrl=" + window.location.href;
  };

  useEffect(() => {
    if (!settings.loaded) {
      getSettings();
    }

    if (settings.loaded && loadingUser) {
      setLoadingUser(false);
      getUser();
    }
  }, [settings, loadingUser, getUser]);

  return (
    <AppContext.Provider value={{ ...settings, login, logout }}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
